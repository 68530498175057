<template>
  <div class="select-container">
    <button class="input" type="button" :disabled="disabled" @click.stop="open = !open">
      {{ selected ? selected.label : placeholder || '' }}
    </button>
    <div class="select-options" v-if="open && !disabled">
      <button
        v-for="option in options"
        class="select-option"
        type="button"
        :key="option.value"
        :style="{ color: option.color }"
        @click.stop="$emit('input', option.value); open = false"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectInput',
  props: {
    value: String,
    options: Array,
    placeholder: String,
    disabled: Boolean,
  },
  computed: {
    selected() {
      return this.options.find((option) => option.value === this.value) || null;
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    window.addEventListener('click', this.close);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.close);
  },
};
</script>

<style scoped>
.select-container {
  position: relative;
  display: inline-block;
  min-width: 140px;
}

.select-container .input {
  background: #393652 url(../assets/ic-chevron-down.svg) no-repeat calc(100% - 12px) center/18px 18px;
  padding-right: 48px;
}

.select b {
  margin: 0 6px;
}

.select i {
  opacity: 0.69;
}

.select-options {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  border-radius: 16px;
  background: #1F1D2C;
  z-index: 10;
  padding: 8px 0;
  overflow: hidden;
}

.select-option {
  display: block;
  width: 100%;
  padding: 8px 24px;
  font-weight: bold;
}

.select-option:hover {
  background: #2C2B3B;
}
</style>
