<template>
  <div class="game-search">
    <div class="search-input" :type="type">
      <i class="ic ic-search"></i>
      <input
        type="text"
        class="input"
        placeholder="Search a game or category…"
        :disabled="disabled"
        @input="searchGames"
        @focus="gamesOpen = !!games.length"
        @click.stop
      >
      <div class="loader" v-if="searching || loading">Loading...</div>
    </div>
    <div class="dropdown" v-if="gamesOpen && !disabled">
      <a class="dropdown-item" v-for="game in filteredGames" :key="game.id" @click="$emit('select', game)">
        <img :src="game.box_art_url" :alt="game.name">
        <div class="title">{{game.name}}</div>
      </a>
    </div>
  </div>
</template>

<script>
import { client } from '../api/api';

export default {
  name: 'GameSearch',
  props: {
    disabled: Boolean,
    loading: Boolean,
    selected: Array,
    excluded: Array,
    type: String,
  },
  data() {
    return {
      gamesOpen: false,
      games: [],
      searching: false,
    };
  },
  computed: {
    filteredGames() {
      const filtered = this.excluded ? this.games.filter((g) => !this.excluded.includes(g.id)) : this.games;
      if (!this.selected) {
        return filtered;
      }
      return filtered.filter((g) => !this.selected.includes(g.id));
    },
  },
  methods: {
    async searchGames(e) {
      clearTimeout(this.searchTO);
      this.searching = false;
      const terms = e.target.value;
      if (terms.length < 3) {
        return;
      }
      this.searching = true;
      this.searchTO = setTimeout(async () => {
        this.games = await client.get('/games/search', { terms });
        this.gamesOpen = true;
        this.searching = false;
      }, 500);
    },
    closeGames() {
      this.gamesOpen = false;
    },
  },
  mounted() {
    window.addEventListener('click', this.closeGames);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeGames);
  },
};
</script>

<style scoped>
input {
  display: block;
  width: 100%;
  background: none;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #F36;
  font: inherit;
  color: #fff;
  padding: 0 16px;
}

.game-search {
  position: relative;
}

.search-input {
  position: relative;
}

.search-input input {
  padding-left: 50px;
}

.search-input[type="dark"] input {
  background: #2C2B3B;
  border: none;
  height: 42px;
}

.search-input input::placeholder {
  color: rgba(255, 255, 255, 0.69);
}

.search-input i {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 18px;
  margin: auto;
}

.search-input .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 8px;
  overflow: auto;
  max-height: 300px;
  border-radius: 8px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  padding: 8px 0;
  background: rgba(31, 29, 44, 1);
  z-index: 4;
}

.dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.dropdown .dropdown-item:hover {
  background: rgba(0, 0, 0, 0.08);
}

.dropdown .dropdown-item img {
  display: block;
  height: 56px;
  border-radius: 4px;
  margin-right: 8px;
}
</style>
