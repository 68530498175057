<template>
  <div class="page poll-edit" v-if="(poll || !$route.params.id) && user">
    <div class="wrapper">
      <div class="title">
        <h1>{{ poll ? 'Edit' : 'New' }} poll</h1>
        <p>{{ poll ? 'Edit' : 'Create' }} your poll!</p>
      </div>
      <PollForm :poll="poll" :user="user" />
      <!-- <div v-if="poll && user" class="poll-container">
        <div class="poll-header">
          <div>
            <div class="poll-header-title">Poll results</div>
            <div>Updated in real-time</div>
          </div>
          <button class="btn outlined" v-if="hasEnded" :disabled="loading" @click="reopenPoll">Reopen poll</button>
          <button class="btn outlined" v-else :disabled="loading" @click="endPoll">End poll</button>
        </div>
        <PollItem :poll="poll" :is-admin="user.admin" :user-id="user.id" layout="edit" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { onSnapshot, doc } from 'firebase/firestore';
import { mapState } from 'vuex';
import { db, auth } from '../api';
import PollForm from '../components/PollForm.vue';
// import PollItem from '../components/PollItem.vue';
import { getFingerprint } from '../utils/fingerprint';
import { client } from '../api/api';

export default {
  name: 'PollEdit',
  data() {
    return {
      loading: false,
      poll: null,
      pollUrl: null,
      games: [],
      gamesOpen: false,
      canVote: false,
      selectedGame: null,
      votes: [],
      totalVotes: 0,
      myVote: null,
      linkCopied: false,
    };
  },
  components: {
    PollForm,
    // PollItem,
  },
  computed: {
    ...mapState(['user', 'authUser']),
    hasEnded() {
      return this.poll?.ended_at ? this.poll.ended_at.toDate() < new Date() : false;
    },
    fingerprint() {
      return getFingerprint();
    },
  },
  watch: {
    $route() {
      this.getPoll();
    },
    poll() {
      this.checkOwner();
    },
    user() {
      this.checkOwner();
    },
  },
  methods: {
    checkOwner() {
      if (!this.poll || this.user === undefined) {
        return;
      }
      if (!this.user || (this.poll.userId !== this.user.id && !this.user.admin)) {
        this.$router.replace(`/polls/${this.poll.id}`);
      }
    },
    async endPoll() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await client.post(`/polls/${this.poll.id}/end`);
      this.loading = false;
    },
    async reopenPoll() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await client.post(`/polls/${this.poll.id}/reopen`);
      this.loading = false;
    },
    getPoll() {
      if (this.pollRef) {
        this.pollRef();
      }
      if (this.$route.params.id) {
        this.pollRef = onSnapshot(doc(db, `polls/${this.$route.params.id}`), (poll) => {
          if (!poll.exists()) {
            return this.$router.push('/');
          }
          const data = poll.data();
          this.poll = {
            ...data,
            created: data.created.toDate(),
            updated: data.updated.toDate(),
          };
          this.pollUrl = `${window.location.origin}/polls/${poll.id}`;
          let totalVotes = 0;
          this.votes = Object.entries(this.poll.votes).reduce((votes, [key, count]) => {
            const game = this.poll.games[key];
            if (game && count > 0) {
              votes.push({
                ...game,
                box_art_url: game.box_art_url.replace('{width}', '52').replace('{height}', '72'),
                count,
              });
              totalVotes += count;
            }
            return votes;
          }, []).sort((a, b) => b.count - a.count);
          this.totalVotes = totalVotes;
          this.canVote = auth.currentUser.uid !== this.poll.userId;
        });
      } else {
        this.poll = null;
      }
    },
  },
  mounted() {
    this.getPoll();
  },
  beforeDestroy() {
    if (this.pollRef) {
      this.pollRef();
    }
  },
};
</script>

<style scoped>
.page {
  padding: 64px 0 0;
}

.title {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 88px;
}

.title h1 {
  position: relative;
  text-transform: uppercase;
  font-size: 36px;
}

.title h1:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  width: 83px;
  height: 23px;
  margin-top: -4px;
  background: url(../assets/title-art.svg) no-repeat center/contain;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;
}

.poll-container {
  width: 448px;
  max-width: 100%;
}

.poll-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.69);
  margin-bottom: 16px;
}

.poll-header .poll-header-title {
  font-weight: 600;
  color: #FFF;
  margin-bottom: 4px;
}
</style>
