<template>
  <form class="poll-form" @submit.prevent="submitPoll" :disabled="loading">
    <div class="form-section">
      <div class="form-section-title">
        <i class="ic ic-title"></i>
        <b>Poll title</b>
        <span>Ensure your title is unique, especially if it's public. It should also be clear, understandable, and comply with our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>.</span>
      </div>
      <div class="option-container">
        <div class="option-label">
          <i class="ic ic-controller"></i>
          <div class="option-title">
            <label for="title">Title</label>
          </div>
        </div>
        <div class="field">
          <input
            id="title"
            name="title"
            class="input"
            type="text"
            placeholder="Enter the title of your poll here."
            v-model="data.title"
            minlength="3"
            maxlength="100"
            required
          >
        </div>
      </div>
    </div>
    <div class="form-section">
      <div class="form-section-title">
        <i class="ic ic-checklist"></i>
        <b>Poll options</b>
      </div>
      <div class="options-block">
        <div class="option-container l">
          <div class="option-label">
            <ToggleButton v-model="data.duration" @input="data.ended_at = $event && !data.ended_at ? getDefaultDate() : data.ended_at" />
            <div class="option-title">
              <label for="duration">Poll duration</label>
            </div>
            <div class="option-content" v-if="data.duration && endAt">
              Ends on <b>{{ endAt[0] }}</b> at <b>{{ endAt[1] }}</b>.
            </div>
          </div>
          <div class="option-content">
            <date-picker
              class="wsip-datepicker"
              :disabled="!data.duration"
              v-model="data.ended_at"
              format="MMM D, YYYY HH:mm"
              :show-second="false"
              type="datetime"
            ></date-picker>
          </div>
        </div>
        <div class="option-container">
          <div class="option-label">
            <ToggleButton v-model="data.public" />
            <div class="option-title">
              <label for="duration">Public poll</label>
            </div>
          </div>
        </div>
        <div class="option-container l">
          <div class="option-label">
            <ToggleButton :value="data.votes_per_user > 1" @input="data.votes_per_user = $event ? 2 : 1" />
            <div class="option-title">
              <label for="duration">People can vote for multiple games</label>
            </div>
          </div>
          <div class="option-content">
            <div class="increment-input" :disabled="data.votes_per_user < 2">
              <button
                type="button"
                class="knob"
                :disabled="data.votes_per_user <= 2"
                @click="data.votes_per_user -= 1"
              >-</button>
              <b>{{ data.votes_per_user }}</b>
              <button
                type="button"
                class="knob"
                :disabled="data.votes_per_user < 2 || data.votes_per_user >= 20"
                @click="data.votes_per_user += 1"
              >+</button>
            </div>
          </div>
        </div>
        <div class="option-container">
          <div class="option-label">
            <ToggleButton v-model="data.hide_votes" />
            <div class="option-title">
              <label for="duration">Hide the number of votes</label>
            </div>
          </div>
        </div>
        <div class="option-container l" v-if="user.id === 'P1AlAWHqLhZlHYLBX7Mkiyq8u8l1'">
          <div class="option-label">
            <ToggleButton :value="data.discord_channel !== null" @input="data.discord_channel = $event ? getDiscordChannels() : null" />
            <div class="option-title">
              <label for="discord_channel">Announce your poll and results on Discord</label>
            </div>
          </div>
          <div class="option-content">
            <SelectInput v-if="user.discord_bot" :options="channels" v-model="data.discord_channel" :disabled="data.discord_channel === null || loading" />
            <button v-else :disabled="data.discord_channel === null || loading || null" class="btn discord" type="button" @click="connectBot">
              <i class="ic ic-discord"></i>
              Add WSIP Bot
            </button>
          </div>
          <div class="option-container" v-if="user.discord_bot && data.discord_channel !== null">
            <div class="option-label">
              <ToggleButton :value="data.discord_announce_poll !== null" @input="data.discord_announce_poll = $event ? 'everyone' : null" />
              <div class="option-title">
                <label for="discord_channel">Use mentions to announce your poll</label>
              </div>
            </div>
            <div class="option-content">
              <SelectInput :options="discordAnnounceOptions" v-model="data.discord_announce_poll" :disabled="data.discord_announce_poll === null || loading" />
            </div>
          </div>
          <div class="option-container" v-if="user.discord_bot && data.discord_channel !== null">
            <div class="option-label">
              <ToggleButton :value="data.discord_announce_results !== null" @input="data.discord_announce_results = $event ? 'everyone' : null" />
              <div class="option-title">
                <label for="discord_channel">Use mentions to announce the results</label>
              </div>
            </div>
            <div class="option-content">
              <SelectInput :options="discordAnnounceOptions" v-model="data.discord_announce_results" :disabled="data.discord_announce_results === null || loading" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-section">
      <div class="form-section-title">
        <i class="ic ic-controller"></i>
        <b>Games</b>
      </div>
      <div class="option-container">
        <div class="option-label">
          <ToggleButton :value="!!data.games" @input="data.games = $event ? [] : null; results = []" />
          <div class="option-title">
            <label for="duration">Limit votes to pre-selected games</label>
          </div>
        </div>
        <div class="field">
          <GameSearch type="dark" :disabled="!data.games || data.games.length >= 100" @select="selectedGames.unshift($event); data.games.push($event.id)" :selected="data.games" :excluded="poll && poll.excludedGames" />
        </div>
      </div>
      <div v-if="data.games">
        <Draggable
          class="search-results"
          v-model="selectedGames"
          :force-fallback="true"
          :group="{ name: 'games', pull: false, put: false }"
          handle=".ic-handle"
        >
          <div class="result" v-for="(result, index) in selectedGames" :key="result.id">
            <div class="result-index">{{ index + 1 }}</div>
            <div class="ic ic-handle"></div>
            <img :src="result.box_art_url.replace('{width}', '56').replace('{height}', '80')" :alt="result.name" class="result-cover">
            <div class="result-name">{{ result.name }}</div>
            <button class="icon-button l" @click="selectedGames.splice(index, 1); data.games = data.games.filter((id) => id !== result.id)">
              <i class="ic small ic-close-circle"></i>
            </button>
          </div>
        </Draggable>
      </div>
    </div>
    <div class="submit">
      <button class="btn" type="submit">{{ poll ? 'Save' : 'Create poll' }}</button>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import Draggable from 'vuedraggable';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '../css/wsip-datepicker.css';
import ToggleButton from './ToggleButton.vue';
import GameSearch from './GameSearch.vue';
import SelectInput from './SelectInput.vue';
import { client } from '../api/api';

const defaultPoll = {
  title: '',
  duration: false,
  hide_votes: false,
  public: true,
  votes_per_user: 1,
  games: null,
  ended_at: null,
  discord_channel: null,
  discord_announce_poll: 'everyone',
  discord_announce_results: 'everyone',
};

export default {
  name: 'PollForm',
  props: {
    poll: Object,
    user: Object,
  },
  components: {
    ToggleButton,
    GameSearch,
    Draggable,
    DatePicker,
    SelectInput,
  },
  data() {
    return {
      vote: null,
      loading: false,
      results: [],
      data: { ...defaultPoll },
      searching: false,
      selectedGames: [],
      channels: [],
      discordAnnounceOptions: [
        { label: '@everyone', value: 'everyone' },
        { label: '@here', value: 'here' },
      ],
    };
  },
  computed: {
    endAt() {
      if (!this.data.ended_at) {
        return null;
      }
      const mom = moment(this.data.ended_at);
      return [mom.format('MMM D YYYY'), mom.format('h.mm a')];
    },
  },
  watch: {
    poll() {
      this.setPoll();
    },
  },
  methods: {
    getDefaultDate() {
      const date = new Date();
      date.setSeconds(0);
      date.setMilliseconds(0);
      date.setHours(date.getHours() + 1);
      return date;
    },
    async searchGames(e) {
      clearTimeout(this.searchTO);
      this.searching = false;
      const terms = e.target.value.trim();
      if (terms.length < 3) {
        return;
      }
      this.searching = true;
      this.searchTO = setTimeout(async () => {
        const games = await client.get('/games/search', { terms });
        this.results = games;
        this.searching = false;
      }, 500);
    },
    async getDiscordChannels() {
      if (this.loading) {
        return;
      }
      if (!this.user.discord_bot) {
        return;
      }
      this.loading = true;
      const channels = await client.get('/discord/channels');
      this.channels = channels.map((channel) => ({
        label: `#${channel.name}`,
        value: channel.id,
      }));
      this.data.discord_channel = this.channels[0]?.value || '';
      this.loading = false;
    },
    async connectBot() {
      if (this.loading) {
        return;
      }
      if (this.user.discord_bot) {
        return;
      }
      this.loading = true;
      const { url } = await client.get('/discord/bot');
      window.open(url, '_blank');
      this.loading = false;
    },
    async submitPoll() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = { ...this.data };
      if (!data.duration) {
        data.ended_at = null;
      }
      if (!data.discord_channel) {
        data.discord_channel = null;
      }
      const poll = await client.post(`/polls${data.pollId ? `/${data.pollId}` : ''}`, data);
      this.loading = false;
      this.$router.push(`/polls/${data.pollId || poll.id}`);
    },
    setPoll() {
      if (this.poll && this.data.id !== this.poll.id) {
        const hasDuration = !!this.poll.ended_at && !!this.poll.duration;
        this.data = {
          pollId: this.poll.id,
          title: this.poll.title || '',
          hide_votes: this.poll.settings?.hide_votes || false,
          public: this.poll.public || false,
          discord_channel: this.poll.settings?.discord_channel || null,
          games: this.poll.settings?.games || null,
          votes_per_user: this.poll.settings?.votes_per_user || 1,
          duration: hasDuration,
          ended_at: this.poll.ended_at?.toDate() || null,
          discord_announce_poll: this.poll.settings?.discord_announce_poll || null,
          discord_announce_results: this.poll.settings?.discord_announce_results || null,
        };
        if (this.data.games) {
          this.selectedGames = this.data.games.map((id) => this.poll.games[id]).filter((g) => g);
        } else {
          this.selectedGames = [];
        }
      } else if (!this.poll) {
        this.data = { ...defaultPoll };
        this.selectedGames = [];
      }
    },
  },
  mounted() {
    this.setPoll();
  },
};
</script>

<style scoped>
.poll-form {
  flex-grow: 1;
}

.poll-form[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.form-section-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.form-section-title a {
  color: #F36;
  text-decoration: underline;
}

.form-section:not(:first-child) {
  margin-top: 40px;
}

.form-section-title:after {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background: rgba(255, 255, 255, 0.46);
}

.form-section-title b {
  text-transform: uppercase;
  font-size: 24px;
  margin: 0 12px 0 8px;
}

.form-section-title span {
  color: rgba(255, 255, 255, 0.69);
  margin-right: 12px;
}

.option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #1F1D2C;
  border-radius: 16px;
  padding: 16px 36px;
  margin-top: 16px;
  gap: 16px;
}

.options-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.options-block .option-container {
  width: calc(40% - 6px);
}

.options-block .option-container.l {
  width: calc(60% - 6px);
}

.option-container .option-container {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.23);
  margin-top: 0;
  padding: 16px 24px;
}

.option-label {
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 32px;
}

.option-title {
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 700;
}

.option-label .option-content {
  color: rgba(255, 255, 255, 0.69);
}

.option-label .option-content b {
  color: #fff;
}

.field-container {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  padding: 0 42px;
}

.field {
  flex-grow: 1;
}

.field label {
  display: block;
  color: rgba(255, 255, 255, 0.69);
  margin-bottom: 8px;
}

.submit {
  text-align: center;
  margin-top: 40px;
}

.submit .btn {
  font-weight: 700;
}

.increment-input {
  display: flex;
  align-items: center;
  gap: 8px;
}

.increment-input b {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  background: #393652;
  border-radius: 24px;
  font-weight: 600;
  height: 42px;
  text-align: center;
}

.increment-input[disabled] b {
  opacity: 0.5;
}

.knob {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: #393652;
  border-radius: 50%;
  padding: 0;
  font-weight: 700;
  line-height: 1;
}

.knob[disabled] {
  opacity: 0.5;
  cursor: default;
}

.search-results {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.result {
  position: relative;
  display: flex;
  align-items: center;
  width: auto !important;
  height: auto !important;
  gap: 12px;
  background: #1F1D2C;
  padding: 12px 24px;
  border-radius: 16px;
  overflow: hidden;
}

.result.sortable-chosen:not(.sortable-fallback) {
  width: 1px !important;
  height: 78px !important;
  background-color: #FC0;
  padding: 0;
}

.result .icon-button {
  position: absolute;
  top: 0;
  right: 0;
}

.result .ic-handle {
  cursor: grab;
  align-self: center;
}

.result .result-cover {
  width: 56px;
  height: 80px;
  object-fit: cover;
  border-radius: 2px;
  flex-shrink: 0;
}

.result .result-index {
  font-size: 20px;
  font-weight: 700;
  margin-right: -6px;
}

.result .result-name {
  display: flex;
  align-items: center;
  max-width: 244px;
  font-weight: 600;
  flex-grow: 1;
}

.search-input {
  background: #2C2B3B url(../assets/ic-search.svg) no-repeat 16px center/24px auto;
  padding-left: 48px;
}

.input-container {
  position: relative;
}

.input-container .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
}

.field .game-search >>> input {
  background: rgba(57, 54, 82, 0.69);
}

.field .game-search >>> input::placeholder {
  color: rgba(255, 255, 255, 0.69);
  opacity: 1;
}

@media screen and (max-width: 880px) {
  .option-container,
  .option-container.l {
    padding: 16px 24px;
  }
  .options-block .option-container,
  .options-block .option-container.l {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .option-container {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }
  .search-results {
    gap: 12px;
  }
  .result {
    width: 100% !important;
  }
  .result .result-cover {
    width: 48px;
    height: auto;
  }
}
</style>
